<template>
    <div>
        <!-- 面包屑 -->
        <!-- <div class="breadcrumb">
            <el-breadcrumb>
                <el-breadcrumb-item :to="{ path: '/enterpriseHome/enterpriseContentt' }">店铺首页</el-breadcrumb-item>
                <el-breadcrumb-item>加工中心</el-breadcrumb-item>
                <el-breadcrumb-item>立式加工中心</el-breadcrumb-item>
            </el-breadcrumb>
        </div> -->

        <!-- 主体内容 -->
        <div class="cont">
            <div class="infoDp" v-loading="loading">
                <div class="swiper">
                    <swiper :options="swiperOptions" style="height: 100%" ref="swiper">
                        <swiper-slide v-for="(i, index) in mainList" :key="index" style="width: 420px;height: 420px;">
                            <img style="width: 100%;height: 100%;object-fit: contain;" :src="i" alt="" />
                        </swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>
                        <div v-if="mainList.length != 1" class="swiper-button-prev" slot="button-prev"></div>
                        <div v-if="mainList.length != 1" class="swiper-button-next" slot="button-next"></div>
                    </swiper>
                </div>

                <div class="r">

                    <div class="name" style="margin-bottom: 20px;">
                        <span class="bt">{{ typeCommodity == 'MS' ? commodityInfoMS.mallSpu?.brandName :
                            commodityInfo.brandName }}</span>
                        <span class="text">{{ typeCommodity == 'MS' ? commodityInfoMS.mallSpu?.title : commodityInfo.title
                        }}</span>
                        <span v-if="typeCommodity == 'MS'" style="color: #FF4242;font-weight: 400;margin-left: 10px;">({{
                            commodityInfoMS.name }})</span>
                    </div>
                    <!-- <div class="introduce">{{ typeCommodity == 'MS' ? commodityInfoMS.mallSpu?.description :
                        commodityInfo.description }}</div> -->
                    <div class="originalPrice">
                        <div class="titl">价格</div>
                        <div class="xiajia" v-if="!value">¥ <span v-if="typeCommodity == 'MS'"> {{
                            commodityInfoMS.seckillPrice }}</span>
                            <span v-else>
                                <span v-if="commodityInfo.spuType == 'discuss'">面议</span>
                                <span v-else>{{ commodityInfo.price }} ~ {{ commodityInfo.price2 }}</span>
                            </span>
                        </div>
                        <div class="xiajia" v-else>¥{{ commodityInfo.spuType == 'discuss' ? '面议' : value }}</div>
                        <div v-if="commodityInfo.type == '2'"
                            style="width: 40px;height: 20px;border-radius: 4px;text-align: center;line-height: 20px;font-size: 12px;color: #fff;background-color: #FF4242;margin-left: 5px;">
                            定金</div>
                        <div class="sales" style="margin-left: 20px;"> <span v-if="typeCommodity == 'MS'"
                                style="width: 100%;display: flex;">
                                <div style="margin-right: 20px;">已抢{{ commodityInfoMS.seckillNum }}</div>
                                <div>限量{{ commodityInfoMS.limitNum }}</div>
                            </span> <span v-else>已售{{ commodityInfo.sales }}件</span></div>
                        
                    </div>

                    <!-- <div class="originalPrice">
                        <div class="titl">价格</div>
                        <div class="xiajia">¥{{ commodityInfo.price }}</div>
                    </div> -->
                    <div class="originalPrice" v-if="couponList.length !== 0 && commodityInfo.spuType !== 'discuss'">
                        <div class="titl">促销</div>
                        <div class="btn" v-if="couponList.find(obj => obj.type == '1' || obj.type == '3')">满{{
                            couponList.find(item => item.type == '1' || item.type == '3').premiseAmount }}减{{
        couponList.find(item => item.type == '1' || item.type == '3').reduceAmount ||
        couponList.find(item => item.type == '1' || item.type == '3').fixationAmount }}</div>
                        <div class="btn" v-if="couponList.find(obj => obj.type == '2')">{{ couponList.find(item => item.type
                            == '2').discount }}折券</div>
                        <div class="ico" @click="drawer = true">领券>></div>
                    </div>
                    <!-- <div class="originalPrice">
                        <div class="titl">物流</div>
                        <div class="dizhi">上海市</div>
                        <div class="zhi">至</div>
                        <el-select v-model="value" placeholder="请选择" style="width: 150px;" size="mini">
                            <el-option label="安徽" value="安徽">
                            </el-option>
                            <el-option label="河南" value="河南">
                            </el-option>
                        </el-select>
                    </div> -->
                    <div class="originalPrice">
                        <div class="titl">服务</div>
                        <div class="dizhi">{{ typeCommodity == 'MS' ? commodityInfoMS.mallSpu?.serviceAssurance :
                            commodityInfo.serviceAssurance }}</div>
                    </div>
                    <div class="originalPrice">
                        <div class="titl">发货地</div>
                        <div class="dizhi">{{ typeCommodity == 'MS' ? commodityInfoMS.mallStore?.address :
                            commodityInfo.address }}</div>
                    </div>
                    <div class="originalPrice">
                        <div class="titl">规格</div>
                        <div v-if="typeCommodity == 'MS'">
                            {{ commodityInfoMS.mallSku?.mallSkuValues[0].detailName }}
                        </div>
                        <div style="display: flex;" v-else>
                            <div v-for="(it, index) in commodityInfo.mallSkuKeys" :key="index">
                                <el-select v-model="it.skuValueStr" :placeholder="`请选择${it.specsName}`"
                                    style="width: 150px;margin-right: 20px;" size="mini">
                                    <el-option :label="i.detailName" :value="i.detailName"
                                        v-for="(i, index) in it.mallSkuValues" :key="index">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>

                        <!--                        
                        <el-select v-model="value" placeholder="请选择每卷长度" style="width: 150px;" size="mini">
                            <el-option label="1m" value="1m">
                            </el-option>
                            <el-option label="10m" value="10m">
                            </el-option>
                        </el-select> -->
                    </div>
                    <div class="originalPrice">
                        <el-input-number :disabled="typeCommodity == 'MS' ? true : false" v-model="num" :min="1"
                            :max="stock" label="数量" controls-position="right" style="width: 100px;"></el-input-number>
                        <div v-if="typeCommodity == 'MS'" style="display: flex;align-items: center;">
                            <div class="botton" @click="freightClick"
                                :style="{ backgroundColor: typeTime == 1 ? '' : '#c0c0c0' }"> {{ typeTime == 0 ?
                                    '还未开始' : typeTime == 1 ? '立即抢购' : '已结束' }}</div>
                            <div class="botton2" @click="freightYJClick">原价购买</div>
                        </div>
                        <div v-else style="display: flex;align-items: center;">
                            <div class="botton" @click="xiadanClick">立即购买</div>
                            <div class="botton2" @click="addCart" v-if="commodityInfo.spuType !== 'discuss'">加入购物车</div>
                            <div class="sc" @click="isCollectClick">
                                <el-icon class="el-icon-star-on" v-if="isCollect == true"
                                    style="color: #FF4242;font-size: 25px;margin-top: -3px;"></el-icon>
                                <el-icon class="el-icon-star-off" style="font-size: 20px;" v-else></el-icon>
                                <span>收藏</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 商品介绍 评论详情 -->
            <div class="xiqngq">
                <div class="l_menu">
                    <div class="l">
                        <div class="headDp" v-loading="loading">
                            <div class="t" style="display: flex;align-items: center;justify-content: space-around;">
                                <div
                                    style="max-width: 150px;font-size: 14px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;display: inline-block;">
                                    {{ commodityInfo.mallStore.storeName }}</div> <span>{{
                                        commodityInfo.mallStore.storeTypeTxt }}</span>
                            </div>
                            <div class="daxix">
                                <div class="sonn">
                                    <div class="name">综合体验：</div>
                                    <div class="rate"><el-rate
                                            v-model="commodityInfo.mallStore.mallStoreEvaluation.serviceValue"
                                            text-color="#666666" disabled disabled-void-color="#C7C5C5"
                                            :colors="['#FD4C4C', '#FD4C4C', '#FD4C4C']">
                                        </el-rate></div>
                                </div>
                                <div class="sonn">
                                    <div class="name">咨询评价：</div>
                                    <div class="rate"><el-rate
                                            v-model="commodityInfo.mallStore.mallStoreEvaluation.consultValue"
                                            text-color="#666666" disabled disabled-void-color="#C7C5C5"
                                            :colors="['#FD4C4C', '#FD4C4C', '#FD4C4C']">
                                        </el-rate></div>
                                </div>
                                <div class="sonn">
                                    <div class="name">质量评价：</div>
                                    <div class="rate"><el-rate
                                            v-model="commodityInfo.mallStore.mallStoreEvaluation.qualityValue"
                                            text-color="#666666" disabled disabled-void-color="#C7C5C5"
                                            :colors="['#FD4C4C', '#FD4C4C', '#FD4C4C']">
                                        </el-rate></div>
                                </div>
                                <div class="sonn">
                                    <div class="name">服务评价：</div>
                                    <div class="rate"><el-rate
                                            v-model="commodityInfo.mallStore.mallStoreEvaluation.serviceValue"
                                            text-color="#666666" disabled disabled-void-color="#C7C5C5"
                                            :colors="['#FD4C4C', '#FD4C4C', '#FD4C4C']">
                                        </el-rate></div>
                                </div>
                                <div class="sonn">
                                    <div class="name">物流评价：</div>
                                    <div class="rate"><el-rate
                                            v-model="commodityInfo.mallStore.mallStoreEvaluation.logisticsValue"
                                            text-color="#666666" disabled disabled-void-color="#C7C5C5"
                                            :colors="['#FD4C4C', '#FD4C4C', '#FD4C4C']">
                                        </el-rate></div>
                                </div>
                                <div class="btn_box">
                                    <div class="l" @click="storeClick">店铺逛逛</div>
                                    <div class="guanz" v-if="isFans == false" @click="followSubscription">
                                        <img src="@/assets/icon/wgzdp.png" alt="">
                                        <div class="text">关注店铺</div>
                                    </div>
                                    <div class="yguanz" v-else @click="followSubscription">
                                        <img src="@/assets/icon/ygzdp.png" alt="">
                                        <div class="text">已关注</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="top">
                            <img src="@/assets/icon/fenl.png" alt="">
                            <div class="text">商品分类</div>
                        </div>
                        <el-menu collapse-transition class="el-menu-vertical-demo" @select="handleSelect"
                            background-color="#F8F8F8" text-color="#333333" active-text-color="#FF4242">
                            <el-submenu :index="item.id + ''" v-for="item in storeClassificationList" :key="item.id">
                                <template slot="title">
                                    <span>{{ item.typeName }}</span>
                                </template>
                                <el-menu-item :index="i.id + ''" v-for="i in item.children" :key="i.id">{{
                                    i.typeName
                                }}</el-menu-item>
                            </el-submenu>
                        </el-menu>
                    </div>
                    <div class="low">
                        <div class="sellLikeHotCakes">
                            <div :class="[i.act ? 'actShow' : '']" @click="sellLikeHotCakes(index)"
                                v-for="(i, index) in sellLikeHotCakesArr" :key="index">{{ i.name }}</div>
                        </div>
                        <div class="conn">
                            <div class="son" @click="commClick(i)" v-for="(i, index) in storeHotSales" :key="index">
                                <div class="img">
                                    <img :src="i.mainImage" alt="">
                                </div>
                                <div class="name">{{ i.title }}</div>
                                <div class="jiag">
                                    <div class="l">¥{{ i.price }}</div>
                                    <div class="r">销量 {{ i.sales }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="introduce_comments" v-loading="loading">
                    <div class="tlt">
                        <div :class="[i.act ? 'actShow' : '']" @click="introduce(index)"
                            v-for="(i, index) in introduceCommentsArr" :key="index">{{ i.name }}</div>
                    </div>
                    <div class="introduce post-content" v-if="typeCommodity == 'MS'" v-show="introduceCommentsIndex == 0">
                        <div class="">{{ typeCommodity == 'MS' ? commodityInfoMS.mallSpu?.description :
                            commodityInfo.description }}</div>
                        <div v-html="commodityInfoMS.mallSpu?.detail"></div>
                    </div>

                    <div class="introduce post-content" v-else v-show="introduceCommentsIndex == 0">
                        <div class="">{{ typeCommodity == 'MS' ? commodityInfoMS.mallSpu?.description :
                            commodityInfo.description }}</div>
                        <div v-html="commodityInfo.detail"></div>
                    </div>
                    <div class="comments" v-show="introduceCommentsIndex == 1">
                        <div class="sppj">商品评价</div>
                        <div class="soo">
                            <div v-if="commodityEvaluate.length == 0"
                                style="width: 100%;text-align: center;margin: 50px auto;color: #666;display: flex;justify-content: center;flex-wrap: wrap;">
                                <img src="@/assets/wsj.png" style="width: 250px;object-fit: contain;" alt="">
                                <div style="width: 100%;margin-top: 10px;">暂无评价</div>
                            </div>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" alt="">
                            </el-dialog>
                            <div class="pl" v-for="pt in commodityEvaluate" :key="pt.evaluateId">
                                <div class="img">
                                    <img @click="imgDia(pt.userImage || pt.mallUserInfo.userImage)"
                                        :src="pt.userImage || pt.mallUserInfo.userImage" alt="">
                                </div>

                                <div class="inf">
                                    <div class="nameTime">
                                        <div class="name">{{ pt.userName }}</div>
                                        <div class="time">{{ pt.createTime }}</div>
                                    </div>
                                    <div class="rate"><el-rate v-model="pt.qualityValue" text-color="#666666" disabled
                                            disabled-void-color="#C7C5C5" :colors="['#FD4C4C', '#FD4C4C', '#FD4C4C']">
                                        </el-rate></div>
                                    <div class="text">
                                        {{ pt.evaluateContent }}
                                    </div>
                                    <div class="img_box">
                                        <div class="img" v-for="(o, ind) in pt.evaluateImage?.split(',')" :key="ind">
                                            <img @click="imgDia(o)" :src="o" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="pl" v-for="(p, ex) in 4" :key="ex">
                                <div class="img">
                                    <img src="@/assets/mrtx.png" alt="">
                                </div>
                                <div class="inf">
                                    <div class="nameTime">
                                        <div class="name">用户名昵称</div>
                                        <div class="time">2023-04-01 13:15:10</div>
                                    </div>
                                    <div class="rate"><el-rate v-model="value2" text-color="#666666" disabled
                                            disabled-void-color="#C7C5C5" :colors="['#FD4C4C', '#FD4C4C', '#FD4C4C']">
                                        </el-rate></div>
                                    <div class="text">
                                        产品质量非常好，服务很到位，非常值得点赞，实现高品质的强力五轴加工，优异加工性能，为客户在高度竞争市场中，提供经济的五轴加工设备
                                    </div>
                                    <div class="img_box">
                                        <div class="img" v-for="(l, dex) in 4" :key="dex">
                                            <img src="https://swiperjs.com/demos/images/nature-2.jpg" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- 抽屉优惠券 -->
        <el-drawer title="优惠券" :visible.sync="drawer" :with-header="true">
            <!-- 优惠券列表 -->
            <div class="yhqList">

                <div class="son" v-for="(item, index) in couponList" :key="index">
                    <div class="l">
                        <div class="jiag" v-if="item.type == '1' || item.type == '4'"> ￥<span>{{ item.reduceAmount ||
                            item.fixationAmount }}</span> </div>
                        <div class="jiag" v-if="item.type == '3'"> ￥<span>{{ item.fixationAmount }}</span> </div>
                        <div class="jiag" v-if="item.type == '2'"> <span>{{ item.discount }}</span> 折</div>
                        <div class="text">满{{ item.premiseAmount }}可用</div>
                    </div>
                    <div class="r">
                        <div class="name">{{ item.storeName }}</div>
                        <div class="text" v-show="item.suitType == '1'">全部商品可用</div>
                        <div class="text" v-show="item.suitType == '2'">指定商品可用</div>
                        <div class="btn" v-show="!item.couponUser && !item.couponTname" @click="claimCouponsClick(item)">
                            立即领券</div>
                        <div class="btn btn3" v-show="item.couponUser || item.couponTname">已领取</div>
                        <div class="prompt">领取后{{ item.validDays }}天内有效</div>
                    </div>
                    <img src="@/assets/mjq.png" v-if="item.type == 1" alt="">
                    <img src="@/assets/jeq.png" v-if="item.type == 3" alt="">
                    <img src="@/assets/kmq.png" v-if="item.type == 4" alt="">
                    <img src="@/assets/zkq.png" v-if="item.type == 2" alt="">
                </div>
            </div>
        </el-drawer>

    </div>
</template>

<script>
import {
    getCommodityProductDetails,
    getCommodityCoupons,
    postCollectSave,
    deleteCollectDel,
    getStoreFollowSubscription,
    getStoreHotSales,
    getCommodityEvaluate,
    getAddCart,
    getFlashKillingCommodityInfo,
    postClaimCoupons
} from "@/utils/api/homeApi/index"
// import { getSaveBrowsingHistory } from "@/utils/api/myApi/index"

export default {
    name: 'productDetails',
    data() {
        return {
            mainList: [],
            loading: true,
            swiperOptions: {
                slidesPerView: 1,
                // 设置分页器
                pagination: {
                    el: ".swiper-pagination",
                    // 设置点击可切换
                    clickable: true,
                    dynamicBullets: true,
                },
                // 设置前进后退按钮
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                // 设置自动轮播
                autoplay: {
                    delay: 5000, // 5秒切换一次
                },
                // 设置轮播可循环
                loop: false,
            },
            // 选择规格后的价格 单价
            value: 0,
            // 选择规格的id
            skuId: '',
            num: 1,
            // 打心
            value2: 3.9,
            // 抽屉
            drawer: false,
            // 店铺热销 热门关注
            sellLikeHotCakesArr: [
                {
                    name: '店铺热销',
                    act: true,
                },
                // {
                //     name: '热门关注',
                //     act: false,
                // },
            ],
            // 商品介绍 评论
            introduceCommentsArr: [
                {
                    name: '商品介绍',
                    act: true,
                },
                {
                    name: '评价详情（0评价）',
                    act: false,
                },
            ],
            // 当前激活的index 商品介绍 评论
            introduceCommentsIndex: 0,
            // 当前商品id
            commodityId: '',
            // 当前规格库存
            stock: 0,
            commodityInfo: {
                mallStore: {
                    mallStoreEvaluation: {
                        consultValue: 0,
                        qualityValue: 0,
                        serviceValue: 0,
                        logisticsValue: 0
                    }
                }
            },
            // 优惠券列表
            couponList: [],
            thePrice: '',
            // 是否收藏
            isCollect: false,
            // 是否关注店铺
            isFans: false,
            // 店铺的商品分类
            storeClassificationList: [],
            // 店铺的热销商品
            storeHotSales: [],
            // 商品评价
            commodityEvaluate: {},
            // 评价信息
            evaluateNum: {},
            mallEvaluatesTotal: 0,
            // 商品类型
            typeCommodity: '',
            // 秒杀商品详情
            commodityInfoMS: {},
            // 秒杀状态
            typeTime: '',
            dialogVisible: false,
            dialogImageUrl: ''
        };
    },
    mounted() {
    },
    created() {
        this.commodityId = this.$route.query.ids
        this.typeCommodity = this.$route.query.type
        this.typeTime = this.$route.query.typeTime
        if (this.typeCommodity == 'MS') {
            this.getCommodityMS()
        } else {
            this.getCommodity()
        }
    },
    watch: {
        // 监视规格发生改变后
        commodityInfo: {
            handler(newVal, oldVal) {
                if (this.typeCommodity == 'MS') return
                let valueArr = []
                newVal.mallSkuKeys?.map(v => {
                    if (v.skuValueStr) {
                        valueArr.push(v.skuValueStr)
                    }
                })
                newVal.mallSku?.map(n => {
                    if (n.valueDetailTxt.slice(0, -1) == valueArr.join(',')) {
                        if (n.price) {
                            this.value = n.price
                        } else {
                            this.value = 0 // 0.01
                        }
                        this.skuId = n.id
                        this.valueArr = valueArr.join(',')
                        // 库存
                        this.stock = n.stock
                        console.log(this.skuId)
                    }
                })
            },
            deep: true,
        },
        '$store.state.isFans'(newVal, oldVal) {
            this.isFans = newVal
        },
        '$store.state.storeClassificationList'(newVal, oldVal) {
            this.storeClassificationList = newVal
        },
        '$store.state.mallStoreInfo'(newVal, oldVal) {
            // if (this.typeCommodity !== 'MS') return
            this.commodityInfo.mallStore = newVal
            this.loading = false
            console.log(newVal, '监视')
        },
    },
    methods: {
        imgDia(url) {
            this.dialogVisible = true
            this.dialogImageUrl = url
        },
        storeClick() {
            window.open(`#/enterpriseHome/enterpriseContent?ids=${localStorage.getItem('storeId')}`)
        },
        commClick(i) {
            window.open(`#/commodity/productDetails?ids=${i.spuId}`, '_blank');
        },
        // changeNumber(){
        //     if(!this.value) {
        //         this.$message.error('请先选择商品规格')
        //         this.num = this.num
        //     } 
        // },
        // 领取优惠券
        async claimCouponsClick(val) {
            console.log(val)
            const res = await postClaimCoupons({
                id: val.id,
                storeId: val.storeId
            })
            console.log(res)
            if (res.statusCode == 8201) {
                this.$message.success('领取成功');
                this.getCoupons()
            } else if (res.statusCode == 7001) {
                this.$message.error(res.message);
            } else {
                this.$message.error('领取失败');
            }
        },
        // 立即抢购秒杀
        freightClick() {
            if (!this.$store.state.userInfo.userId) {
                localStorage.setItem('historyRoute', this.$route.fullPath);
                return this.$router.push('/login')
            }
            if (this.typeTime != 1) return
            if (this.commodityInfoMS.mallSku?.stock <= 0) return this.$message.error('库存不足')
            localStorage.setItem('mallStoreObjMS', JSON.stringify(this.commodityInfoMS));
            window.open('#/payment?type=MS', '_blank');
        },
        // 原价购买
        freightYJClick() {
            console.log(this.commodityInfoMS.spuId)
            window.open(`#/commodity/productDetails?ids=${this.commodityInfoMS.spuId}`, '_blank');
        },
        // 获取秒杀商品详情
        async getCommodityMS() {
            const res = await getFlashKillingCommodityInfo(this.commodityId)
            if (res.statusCode == 8201) {
                localStorage.setItem('storeId', res.data.storeId)
                this.commodityInfoMS = res.data
                this.mainList = res.data.picUrl.split(',')
                this.$store.commit("getCommodity", res.data)
                this.storeId = res.data.storeId
                this.getStoreHot({
                    type: 'SALES',
                    storeId: res.data.storeId,
                    currentPage: 1,
                    pageSize: 5
                })
                this.getEvaluate({
                    // supId: '2451910',
                    supId: res.data.spuId,
                    currentPage: 1,
                    pageSize: 10
                })
                localStorage.setItem('mallStoreObjMS', JSON.stringify(res.data));
                this.loading = false
                this.getSaveBrowsingHistoryClick(res.data.spuId)
            }
        },
        // 店铺关注订阅与取消
        async followSubscription() {
            if (this.isFans) {
                const res = await getStoreFollowSubscription({ type: 'cancel', ids: this.storeId })
                if (res.data) {
                    this.$message({
                        message: "已取消关注"
                    })
                    this.isFans = false
                }
            } else {
                const res = await getStoreFollowSubscription({ type: 'fans', ids: this.storeId })
                if (res.data) {
                    this.$message({
                        message: "关注成功",
                        type: 'success',
                    })
                    this.isFans = true
                }
            }
            this.$store.commit('getIsFans', this.isFans)
        },
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
            this.$router.push(`/enterpriseHome/enterpriseCommodity?tabArrIndex=1&storeCategoryId=${key}`)
        },
        // 点击收藏
        async isCollectClick() {
            if (this.isCollect) {
                const res = await deleteCollectDel(this.commodityId)
                if (res.data) {
                    this.isCollect = false
                    this.$message({
                        message: "已取消收藏",
                    })
                }
            } else {
                const res = await postCollectSave(this.commodityId)
                if (res.data) {
                    this.isCollect = true
                    this.$message({
                        message: "收藏成功",
                        type: 'success',
                    })
                }
            }
        },
        // 获取商品详情
        async getCommodity() {
            const res = await getCommodityProductDetails(this.commodityId)
            if (res.statusCode == 8201) {
                localStorage.setItem('storeId', res.data.storeId)
                this.commodityInfo = res.data
                this.mainList = res.data.img.split(',')
                this.isCollect = res.data.isCollect
                this.loading = false
                this.$store.commit("getCommodity", res.data)
                this.storeId = res.data.storeId
                this.getCoupons()
                this.getStoreHot({
                    type: 'SALES',
                    storeId: res.data.storeId,
                    currentPage: 1,
                    pageSize: 5
                })
                this.getEvaluate({
                    // supId: '2451910',
                    supId: this.commodityId,
                    currentPage: 1,
                    pageSize: 10
                })
                // this.getSaveBrowsingHistoryClick(res.data.spuId)
            }
        },
        // 保存浏览记录
        async getSaveBrowsingHistoryClick(id) {
            let data = {
                spuId: id,
                type: 'app'
            }
            await getSaveBrowsingHistory(data)
        },
        // 加入购物车
        async addCart() {
            if (!this.$store.state.userInfo.userId) {
                localStorage.setItem('historyRoute', this.$route.fullPath);
                return this.$router.push('/login')
            }
            if (this.skuId == '') return this.$message({
                message: "请先选择商品规格",
                type: 'error'
            })

            const res = await getAddCart({ skuId: this.skuId, num: this.num })
            if (res.data) {
                this.$message.success("已添加至购物车")
                this.$store.commit('getAShoppingCartList')
            }
        },
        // 普通商品购买跳转
        xiadanClick() {
            if (!this.$store.state.userInfo.userId) {
                localStorage.setItem('historyRoute', this.$route.fullPath);
                return this.$router.push('/login')
            }
            if (this.skuId == '') return this.$message({
                message: "请先选择商品规格",
                type: 'error'
            })
            if (this.stock - this.num < 0) return this.$message.error('库存不足')
            console.log(this.valueArr)
            // 购买数量
            this.commodityInfo.numXZ = this.num
            // 已选规格单价
            this.commodityInfo.unitPriceXZ = this.value
            // 选择规格的id
            this.commodityInfo.skuIdXZ = this.skuId
            // 选择的规格
            this.commodityInfo.valueArrXZ = this.valueArr
            localStorage.setItem('mallStoreObjPT', JSON.stringify(this.commodityInfo));
            window.open('#/payment?type=PT', '_blank');
        },
        // 获取店铺热门商品
        async getStoreHot(data) {
            const res = await getStoreHotSales(data)
            if (res.statusCode == 8201) {
                this.storeHotSales = res.data.records
            }
        },
        // 获取商品评价
        async getEvaluate(data) {
            const res = await getCommodityEvaluate(data)
            if (res.statusCode == 8201) {
                this.commodityEvaluate = res.data.mallEvaluates.records
                this.evaluateNum = res.data.evaluateNum
                this.mallEvaluatesTotal = res.data.mallEvaluates.total
                this.introduceCommentsArr[1].name = `评价详情（${this.evaluateNum.allNum}评价）`
            }
        },
        // 根据商品id和商家id查看可用优惠券
        async getCoupons() {
            const res = await getCommodityCoupons({ spuId: this.commodityId, storeId: this.storeId })
            if (res.statusCode == 8201) {
                this.couponList = res.data.records
                console.log(res.data, '优惠券')
            }
        },
        sellLikeHotCakes(index) {
            this.sellLikeHotCakesArr.map(m => m.act = false)
            this.sellLikeHotCakesArr[index].act = true
        },
        introduce(index) {
            this.introduceCommentsArr.map(m => m.act = false)
            this.introduceCommentsArr[index].act = true
            this.introduceCommentsIndex = index
        },
    }


}
</script>

<style lang="less" scoped>
.breadcrumb {
    width: 1200px;
    margin: 0px auto;
    padding: 15px;
    box-sizing: border-box;
}

.cont {
    width: 1200px;
    height: auto;
    margin: 20px auto;
    padding: 30px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 8px;

    >.infoDp {
        width: 100%;
        height: auto;
        display: flex;


        >.swiper {
            width: 420px;
            height: 420px;
            margin-bottom: 40px;

            .title_box {
                width: 100%;
                height: 36px;
                position: absolute;
                top: 0;
                font-size: 14px;
                color: #FCFF66;
                z-index: 999;
                text-align: center;
                line-height: 36px;
                background-color: rgba(0, 0, 0, 0.70);
            }
        }

        .r {
            width: 600px;
            height: auto;
            margin-left: 80px;
            padding-top: 40px;
            box-sizing: border-box;

            >.name {
                width: 100%;

                >.bt {
                    display: inline-block;
                    padding: 4px 10px;
                    border-radius: 4px;
                    background-color: #FF4242;
                    color: #fff;
                    font-size: 14px;
                    text-align: center;
                    margin-right: 10px;
                }

                >.text {
                    color: #333;
                    font-size: 20px;
                    font-weight: bold;
                }
            }

            >.introduce {
                width: 100%;
                font-size: 14px;
                color: #FF4242;
                margin-top: 10px;
                margin-bottom: 35px;
                overflow: hidden;

                >img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            >.originalPrice {
                width: 100%;
                display: flex;
                align-items: center;
                color: #999999;
                font-size: 14px;
                margin-bottom: 13px;

                >.titl {
                    margin-right: 20px;
                }

                >.jiag {
                    margin-right: 40px;
                }

                >.xiajia {
                    color: #FF4242;
                    font-size: 18px;
                    font-weight: bold;
                }

                >.btn {
                    padding: 3px 10px;
                    box-sizing: border-box;
                    background-color: #FFEDED;
                    border: 1px solid #FF4242;
                    border-radius: 4px;
                    font-size: 12px;
                    color: #FF4242;
                    margin-right: 10px;
                    cursor: pointer;
                }

                >.zhi {
                    margin: 0 20px;
                }

                >.dizhi {
                    font-size: 14px;
                    color: #333;
                }

                >.ico {
                    color: #FF4242;
                    cursor: pointer;
                }

                .botton {
                    width: 180px;
                    height: 44px;
                    background: #FF4242;
                    border-radius: 4px 4px 4px 4px;
                    text-align: center;
                    line-height: 44px;
                    color: #fff;
                    font-size: 20px;
                    margin-left: 20px;
                    cursor: pointer;
                }

                .botton2 {
                    width: 180px;
                    border-radius: 4px 4px 4px 4px;
                    height: 44px;
                    border: 1px solid #FF4242;
                    background-color: #fff;
                    color: #FF4242;
                    font-size: 20px;
                    text-align: center;
                    line-height: 42px;
                    margin-left: 20px;
                    cursor: pointer;
                }

                .sc {
                    width: 80px;
                    height: 30px;
                    display: flex;
                    cursor: pointer;
                    align-items: center;
                    margin-left: 15px;

                    >span {
                        color: #999;
                        font-size: 12px;
                        margin-left: 5px;
                    }
                }
            }
        }
    }

    >.xiqngq {
        width: 100%;
        height: auto;
        display: flex;

        >.l_menu {
            width: auto;
            height: auto;

            >.l {
                width: 230px;
                overflow: hidden;
                user-select: none;

                >.headDp {
                    width: 228px;
                    height: 250px;
                    border: 1px solid rgba(153, 153, 153, 0.50);
                    margin-bottom: 20px;

                    >.t {
                        width: 100%;
                        height: 34px;
                        background-color: #F5F5F5;
                        border-bottom: 1px solid rgba(153, 153, 153, 0.50);
                        text-align: center;
                        line-height: 34px;
                        font-size: #333333;
                        font-size: 20px;
                        font-weight: bold;
                        box-sizing: border-box;

                        >span {
                            display: inline-block;
                            width: 52px;
                            height: 16px;
                            color: #fff;
                            font-size: 14px;
                            font-weight: 300px;
                            text-align: center;
                            line-height: 16px;
                            background-color: #FF4242;
                            border-radius: 4px;
                        }
                    }

                    >.daxix {
                        width: 100%;
                        height: auto;
                        padding: 12px;
                        box-sizing: border-box;

                        >.sonn {
                            display: flex;
                            align-items: center;
                            margin-top: 10px;

                            >.name {
                                font-size: 12px;
                                color: #666;
                            }
                        }

                        >.btn_box {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            margin-top: 15px;
                            box-sizing: border-box;

                            >.l {
                                width: 80px;
                                height: 26px;
                                border-radius: 4px;
                                border: 1px solid #999999;
                                text-align: center;
                                line-height: 26px;
                                color: #666666;
                                font-size: 12px;
                                cursor: pointer;
                            }

                            >.guanz {
                                cursor: pointer;
                                width: 90px;
                                height: 28px;
                                padding: 1px 8px;
                                box-sizing: border-box;
                                border-radius: 4px;
                                border: 1px solid #FF7D7D;
                                background-color: #FF4242;
                                display: flex;
                                align-items: center;
                                font-size: 12px;
                                color: #fff;

                                >img {
                                    width: 13px;
                                    margin-right: 3px;
                                }
                            }

                            >.yguanz {
                                cursor: pointer;
                                width: 90px;
                                height: 28px;
                                padding: 1px 8px;
                                box-sizing: border-box;
                                border-radius: 4px;
                                border: 1px solid #FF7D7D;
                                background-color: #fff;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 12px;
                                color: #FF4242;

                                >img {
                                    width: 13px;
                                    margin-right: 3px;
                                }
                            }
                        }
                    }
                }

                >.top {
                    width: 100%;
                    height: 45px;
                    background-color: #FF4242;
                    padding: 0 15px;
                    box-sizing: border-box;
                    font-size: 18px;
                    color: #fff;
                    display: flex;
                    align-items: center;

                    >img {
                        margin-right: 10px;
                    }
                }
            }

            .low {
                width: auto;
                height: auto;
                margin-top: 10px;
                border: 1px solid rgba(153, 153, 153, 0.50);

                >.sellLikeHotCakes {
                    width: 100%;
                    display: flex;

                    >div {
                        // width: 50%;
                        width: 100%;
                        height: 42px;
                        background-color: #F5F5F5;
                        font-size: 14px;
                        color: #333;
                        text-align: center;
                        line-height: 42px;
                        cursor: pointer;
                    }
                }

                .conn {
                    width: 230px;
                    height: auto;
                    // padding: 0 45px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;

                    >.son {
                        width: 100%;
                        height: auto;
                        margin-top: 30px;
                        padding: 0px 20px 20px 20px;
                        box-sizing: border-box;

                        >.img {
                            // width: 200px;
                            height: 200px;
                            border-radius: 6px;
                            overflow: hidden;
                            // border: 1px solid rgba(0, 0, 0, .1);

                            >img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }

                        >.name {
                            font-size: 12px;
                            color: #000;
                            white-space: nowrap;
                            /* 不自动换行 */
                            overflow: hidden;
                            /* 超出部分隐藏 */
                            text-overflow: ellipsis;
                            /* 超出部分显示省略号 */
                            margin-top: 13px;
                        }

                        >.jiag {
                            display: flex;
                            margin-top: 10px;

                            >.l {
                                font-size: 16px;
                                font-weight: bold;
                                color: #ff4242;
                                margin-right: 6px;
                            }

                            >.r {
                                font-size: 12px;
                                color: #666;
                            }
                        }
                    }
                }
            }

        }

        >.introduce_comments {
            width: 860px;
            height: auto;
            margin-left: 40px;

            >.tlt {
                width: 100%;
                height: 42px;
                background-color: #F7F7F7;
                display: flex;
                margin-bottom: 20px;

                >div {
                    padding: 12px 25px;
                    box-sizing: border-box;
                    background-color: #F5F5F5;
                    font-size: 14px;
                    color: #333;
                    text-align: center;
                    cursor: pointer;
                }
            }

            >.introduce {
                width: 100%;
                height: auto;
                box-sizing: border-box;
                overflow: auto;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }

                >.intero {
                    width: 100%;
                    font-size: 12px;
                    color: #666;
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 20px;
                    padding: 0 10px;

                    >.son {
                        margin-right: 22px;
                        margin-bottom: 10px;
                    }
                }

                >.img {
                    width: 100%;
                    height: 100%;
                    margin-top: 10px;
                    padding: 20px;
                    box-sizing: border-box;

                    >img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            >.comments {
                width: 100%;
                height: auto;
                box-sizing: border-box;

                >.sppj {
                    width: 100%;
                    height: 42px;
                    background-color: #F7F7F7;
                    padding: 12px 25px;
                    box-sizing: border-box;
                    font-size: 14px;
                    color: #333;
                    margin-top: 15px;
                }

                >.soo {
                    width: 100%;
                    height: auto;
                    padding: 10px 15px;
                    box-sizing: border-box;
                    margin-top: 30px;

                    >.pl {
                        width: 100%;
                        height: auto;
                        display: flex;
                        margin-bottom: 30px;
                        padding-bottom: 30px;
                        box-sizing: border-box;
                        border-bottom: 1px solid #D9D9D9;

                        >.img {
                            width: 64px;
                            height: 64px;
                            border-radius: 50%;
                            overflow: hidden;

                            >img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        >.inf {
                            width: 740px;
                            height: auto;
                            margin-left: 20px;

                            >.nameTime {
                                width: 100%;
                                display: flex;

                                >.name {
                                    color: #333;
                                    font-size: 14px;
                                    font-weight: bold;
                                    margin-right: 25px;
                                }

                                >.time {
                                    color: #666;
                                    font-size: 12px;
                                }
                            }

                            >.rate {
                                margin-top: 10px;
                            }

                            >.text {
                                font-size: 14px;
                                color: #333;
                                margin-top: 15px;
                            }

                            >.img_box {
                                width: 100%;
                                height: auto;
                                display: flex;
                                align-items: center;
                                margin-top: 15px;

                                >.img {
                                    width: 80px;
                                    height: 80px;
                                    margin-right: 15px;

                                    >img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}


// 抽屉
.yhqList {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;

    >.son {
        width: 330px;
        height: 120px;
        margin-top: 20px;
        background: url(../../assets/lqyhbg.png);
        background-size: 100% 100%;
        display: flex;
        position: relative;

        >.l {
            width: 120px;
            height: 120px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            >.jiag {
                color: #FF4242;
                font-size: 16px;

                >span {
                    font-size: 30px;
                    font-weight: bold;
                }
            }

            >.text {
                font-size: 12px;
                color: #666;
                margin-top: 5px;
            }
        }

        >.r {
            width: 210px;
            height: 120px;
            padding: 20px 10px;
            box-sizing: border-box;
            box-sizing: border-box;

            >.name {
                font-size: 14px;
                color: #333;
            }

            >.text {
                font-size: 12px;
                color: #999;
                margin-top: 2px;
            }

            >.btn {
                width: 71px;
                height: 24px;
                border-radius: 18px;
                background-color: #FF4242;
                color: #fff;
                cursor: pointer;
                text-align: center;
                line-height: 24px;
                font-size: 14px;
                margin-left: 100px;
                margin-top: 0px;
            }

            >.btn2 {
                background-color: #999999;
            }

            >.btn3 {
                border: 1px solid #FF4242;
                background-color: rgba(255, 66, 66, .2);
                color: #FF4242;
            }


            >.prompt {
                color: #999;
                font-size: 12px;
                margin-left: 90px;
                margin-top: 20px;
            }
        }

        >img {
            width: auto;
            height: 18px;
            position: absolute;
            top: 4px;
            left: 8px;
        }
    }
}

.actShow {
    background-color: #FF4242 !important;
    color: #fff !important;
}

/deep/ .el-drawer__open .el-drawer.rtl {
    width: 370px !important;
}

/deep/ .el-drawer {
    width: 370px !important;
}

// 右
.swiper-button-next {
    background-color: rgba(0, 0, 0, 0.3);
    margin-right: -10px;
}

.swiper-button-next:after,
.swiper-button-next:after {
    font-size: 22px;
    color: #fff;
}

// 左
.swiper-button-prev {
    background-color: rgba(0, 0, 0, 0.3);
    margin-left: -10px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    font-size: 22px;
    color: #fff;
}

/deep/ .swiper-pagination-bullet-active {
    background-color: #ff4242 !important;
}

/deep/ .el-rate__icon {
    margin-right: 0 !important;
}
</style>